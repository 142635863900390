<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="5"
                  sm="4"
                >
                  <b-form-group
                    label-for="admissionRoundId"
                  >
                    <v-select
                      id="admissionRoundId"
                      v-model="filter.admissionRoundId"
                      :options="admissionRounds"
                      :reduce="option => option.value"
                      placeholder="Đợt xét tuyển"
                      @input="onChangeAdmissionRound"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="7"
                  sm="5"
                >
                  <b-form-group
                    title="Phương thức xét tuyển"
                    label-for="admissionFormId"
                  >
                    <v-select
                      id="admissionFormId"
                      v-model="filter.admissionFormId"
                      :options="dataListAdmissionForm"
                      :reduce="(option) => option.value"
                      placeholder="Phương thức xét tuyển"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="3"
                  class="text-sm-right text-center"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="onSearchMainData"
                  >
                    <feather-icon icon="FilterIcon" /> Lọc
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-0">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  line-numbers
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          variant="info"
                          class="btn-icon"
                          size="sm"
                          @click="onShowsStudentExaminationRoom(props.row)"
                        >
                          <feather-icon icon="UsersIcon" />
                        </b-button>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

    <b-modal
      id="studentExaminationRoomModal"
      centered
      :title="`Danh sách sinh viên: ${(currentExaminationRoom)?currentExaminationRoom.name : ''}`"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
    >
      <ApplicantStudentExaminationRoom
        :ext-data="currentExaminationRoom"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BModal, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ApplicantStudentExaminationRoom from '@/views/admission/applicant-student-examination-room/ApplicantStudentExaminationRoom'
import { getUser } from '@/auth/utils'

export default {
  name: 'SupervisorRoom',
  directives: {
    Ripple,
  },
  components: {
    BForm,
    ApplicantStudentExaminationRoom,
    BFormGroup,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BModal,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      countRoom: 1,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        supervisorId: getUser().employeeId,
        admissionRoundId: null,
        admissionFormId: null,
        code: null,
        name: null,
        status: null,
        sort: null,
      },
      currentExaminationRoom: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      currentExaminationRoomId: null,
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'admissionExaminationRoom/roomBySupervisor',
      totalRows: 'admissionExaminationRoom/totalRoomBySupervisor',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionExaminationRoom/admissionForms',
    }),
    columns() {
      return [
        {
          label: 'Tên phòng thi',
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Phòng/Địa điểm thi',
          field: 'roomName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Bắt đầu',
          field: 'startAt',
          width: '120px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Kết thúc',
          field: 'endAt',
          width: '120px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Đợt xét tuyển',
          field: 'admissionRoundName',
          sortable: false,
          width: '120px',
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '40px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_SUPERVISOR_EXAMINATION_ROOM)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      await this.getMainDataStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'admissionExaminationRoom/getRoomBySupervisor',
      readAdmissionFormByAdmissionRoundId: 'admissionExaminationRoom/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataStore()
    },
    async getMainDataStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getMainDataStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      await this.getMainDataStore()
    },
    async onSucceed() {
      await this.getMainDataStore()
    },
    async onChangeAdmissionRound(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    async onSearchMainData() {
      await this.getMainDataStore()
    },
    onShowsStudentExaminationRoom(row) {
      this.currentExaminationRoom = row
      this.$bvModal.show('studentExaminationRoomModal')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
